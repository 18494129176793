import type { CheckoutCustomPayload } from "@product/rosetta-sdk";
import { useCallback } from "react";

import {
  registerMixPanelParameters,
  trackMixPanel,
  useMixpanel,
} from "scmp-app/components/mixpanel";
import { sendGTMSubscribeVariables } from "scmp-app/components/tracking/google-tag-manager/apis";

export const useRosettaCheckoutCustomEventHandler = () => {
  const mixpanel = useMixpanel();

  const handleCustomEvent = useCallback(
    (payload: CheckoutCustomPayload) => {
      const handleCheckoutCustomEventTracking = () => {
        const {
          eventName,
          params: { meter },
        } = payload;

        if (!eventName || !meter) return;

        switch (eventName) {
          case "meter-direct-checkout": {
            const {
              params: { action: eventAction, plan: period },
            } = payload;

            if (period) {
              const {
                params: { currency, price },
              } = payload;
              const plan = `${period === "year" ? "Annual" : "Monthly"}: Digital | ${currency}`;
              sendGTMSubscribeVariables({ meter, period, plan, price });
            }
            if (!eventAction) return;

            registerMixPanelParameters(mixpanel.result, {
              "Metering Article Count": meter,
            });
            trackMixPanel(mixpanel.result, {
              name: "Subscription Metering",
              property: {
                "Metering Article Count": meter,
                "User Action": "Click Subscribe",
              },
            });
            break;
          }
        }
      };

      handleCheckoutCustomEventTracking();
    },
    [mixpanel.result],
  );

  return {
    handleCustomEvent,
  };
};
